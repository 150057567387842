<template>
  <section>
      <div class="content-header">
        <h2>Micro resilience  </h2>
      </div>
      <div class="content-wrapper">
        <p>Bonnie St John, a former Paralympic skier and acclaimed public speaker co-authored a book with her husband, Allan Haines entitled <strong class="bold">“Micro-Resilience: Minor Shifts for Major Boosts in Focus, Drive and Energy”</strong>.</p>
        <p>The authors identify five areas where you can engage in small but important activities that will help you remain well.</p>
        <div class="accordion">
              <accordion
                headerText="Refocusing your brain "
                accordionID="collapse01"
              >
                <p>Refocus more quickly between bouts of effort by resting your mind and keeping a neutral emotional space.</p>
              </accordion>
              <accordion
                headerText="Resetting your primitive alarms "
                accordionID="collapse02"
              >
                <p>Moments of stress can cause our emotions to hijack our brain and prevent us from thinking clearly. Naming the emotion you’re experiencing can decrease feelings of panic and stimulate rational thinking.  </p>
              </accordion>
              <accordion
                headerText="Reframing your attitude "
                accordionID="collapse03"
              >
                <p>Maintain an optimal stress level by sending new messaging to the brain about what constitutes or doesn’t constitute a threat.</p>
              </accordion>
              <accordion
                headerText="Refreshing your body "
                accordionID="collapse04"
              >
                <p>Train your body to respond to times of stress by feeding it healthy foods and hydrating strategically.</p>
              </accordion>
              <accordion
                headerText="Renewing your spirit  "
                accordionID="collapse05"
              >
                <p>Create a “first aid kit for joy” that you can nurture your spirit at any given moment. Your kit might hold souvenirs, photos, music, and even scents that bring you joy. Turn to your kit whenever you’re struggling and need a reset. </p>
              </accordion>
            </div>
            <p class="lm ">Learn more</p>
            <p>Find out more about how minor shifts in our thinking and behaviour can mean big changes for our personal resilience in the book, <a href="https://bonniestjohn.com/books/micro-resilience/" target="_blank">Micro-Resilience: Minor Shifts for Major Boosts in Focus, Drive, and Energy</a> by Bonnie St John and Allen P. Haines.</p>
      </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
